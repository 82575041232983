import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import BagPackageReader from './bag-package-reader';

function BagPackageReaderPage() {
  const location = useLocation();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  const vehicleLicensePlate =
    location.state?.vehicleLicensePlate || 'Não disponível';

  return (
    <BagPackageReader
      contextLicensePlate={vehicleLicensePlate}
      goBack={handleGoBack}
    />
  );
}

export default BagPackageReaderPage;
