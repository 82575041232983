import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowForwardIos } from '@material-ui/icons';
import { colors } from '@loggi/mar';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import {
  SWITCHES,
  COGNITO_DISTRIBUTION_CENTER,
  DISTRIBUTE_TO,
  ROUTES
} from '../../constants';
import getUserType from '../../app/access-control/access-control-service';
import HeaderWithReturn from '../../app/components/header-with-return';
import { DistributeProcessContext } from './distribute-process-context';
import IdentifyDriverScreen from './identify-driver-screen';
import IdentifyReceiverScreen from './identify-receiver-screen';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { useFeature, useFeatureValue } from '../../app/hooks/use-feature';

export const TYPES_TITLE = {
  DELIVERY: 'Entregador',
  TRANSFER: 'Transferência',
  PARTNER: 'Parceiro',
  REDISPATCH: 'Correios',
  RECEIVER: 'Recebedor'
};

export default function DistributeIdentification() {
  const history = useHistory();
  const { distributeType } = useContext(DistributeProcessContext);

  const renderIdentifyDriverScreen = () => {
    return <IdentifyDriverScreen />;
  };

  const renderIdentifyReceiverScreen = () => {
    return <IdentifyReceiverScreen />;
  };

  const distributeTypeDecisions = {
    [DISTRIBUTE_TO.FLECHA_ROUTE]: renderIdentifyDriverScreen,
    [DISTRIBUTE_TO.TRANSFER]: () =>
      history.push({
        pathname: ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION
      }),
    [DISTRIBUTE_TO.PARTNER]: () =>
      history.push({
        pathname: ROUTES.DISTRIBUTE_PARTNER
      }),
    [DISTRIBUTE_TO.REDISPATCH]: () =>
      history.push({ pathname: ROUTES.DISTRIBUTE.ROOT }),
    [DISTRIBUTE_TO.RECEIVER]: renderIdentifyReceiverScreen,
    [DISTRIBUTE_TO.DELIVERY]: () =>
      history.push({ pathname: ROUTES.DISTRIBUTE.ROOT })
  };

  const identificationScreen = distributeTypeDecisions[distributeType] ? (
    distributeTypeDecisions[distributeType]()
  ) : (
    <DistributeIdentificationScreen />
  );

  return <Box maxWidth="xs">{identificationScreen}</Box>;
}

export function DistributeIdentificationScreen() {
  const history = useHistory();

  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  const { setDistributeType } = useContext(DistributeProcessContext);

  const [errorMessage, setErrorMessage] = useState('');

  const disableDistributeTransfers = useFeature(
    SWITCHES.disableDistributeTransfers
  );

  const enablePartnerDistributeButtonByDC = useFeature(
    SWITCHES.enablePartnerDistributeButtonByDC
  );

  const enablePartnerButtonInDistributeByEmail = useFeatureValue(
    SWITCHES.enablePartnerButtonInDistributeByEmail
  );

  const enableRedispatchMenu = useFeature(SWITCHES.enableRedispatchMenu);

  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();

  const goBack = () =>
    history.push({
      pathname: '/'
    });

  const isUserAgency = () => {
    return (
      getUserType(selectedDistributionCenter) ===
      COGNITO_DISTRIBUTION_CENTER.AGENCY_USER
    );
  };

  const isUserLeve =
    getUserType(selectedDistributionCenter) ===
    COGNITO_DISTRIBUTION_CENTER.LEVE_USER;

  const handleIdentifyDistribution = title => {
    switch (title) {
      case TYPES_TITLE.DELIVERY: {
        setDistributeType(DISTRIBUTE_TO.DELIVERY);
        break;
      }
      case TYPES_TITLE.TRANSFER: {
        setDistributeType(DISTRIBUTE_TO.TRANSFER);
        break;
      }
      case TYPES_TITLE.REDISPATCH: {
        setDistributeType(DISTRIBUTE_TO.REDISPATCH);
        break;
      }
      case TYPES_TITLE.RECEIVER: {
        setDistributeType(DISTRIBUTE_TO.RECEIVER);
        break;
      }
      case TYPES_TITLE.PARTNER: {
        setDistributeType(DISTRIBUTE_TO.PARTNER);
        break;
      }
      default: {
        setDistributeType(null);
        break;
      }
    }
  };

  const getEnabledTypes = () => {
    const types = [];

    if (isUserAgency()) {
      types.push(TYPES_TITLE.DELIVERY);
      if (enableRedispatchMenu) {
        types.push(TYPES_TITLE.REDISPATCH);
      }
    }
    // show distribute transfers by default
    if (!disableDistributeTransfers || isUserLeve) {
      types.push(TYPES_TITLE.TRANSFER);
    }

    // show distribute partner button by DC and Authenticated User
    if (
      !disableDistributeTransfers &&
      enablePartnerDistributeButtonByDC &&
      !isUserLeve &&
      enablePartnerButtonInDistributeByEmail.includes(authenticatedUser.email)
    ) {
      types.push(TYPES_TITLE.PARTNER);
    }
    return types;
  };

  const enabledTypes = getEnabledTypes();

  useEffect(() => {
    // if we only have one enabled distribute type, we should automatically select it
    // if we have nothing we should redirect to menu
    if (!enabledTypes.length) {
      setErrorMessage('Opa! Parece que você não tem acesso a essa área.');
    } else if (enabledTypes.length === 1) {
      handleIdentifyDistribution(enabledTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledTypes]);

  return (
    <Box
      data-testid="distribute-identification"
      bgcolor={colors.root[0]}
      height="100vh"
    >
      <HeaderWithReturn
        title="Distribuir"
        onReturn={goBack}
        bgcolor={colors.root[0]}
      />
      <Container maxWidth="xs">
        <Box
          pt={8}
          display="flex"
          flexDirection="column"
          height={1}
          overflow="hidden"
        >
          <Box mt={3}>
            <Typography variant="h5">
              Para quem você quer movimentar?
            </Typography>
          </Box>
        </Box>
      </Container>
      <Container maxWidth="xs" disableGutters>
        <Box px={{ xs: '.25rem', sm: '1.25rem' }}>
          <Box display="flex" flexDirection="column" height={1} mt={3}>
            {errorMessage && (
              <Box mt={1.5}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            )}
            <List>
              {Object.values(enabledTypes).map(type => (
                <ListItem
                  button
                  divider
                  disableGutters
                  dense
                  data-testid={`list-item-${type}`}
                  key={type}
                  onClick={() => handleIdentifyDistribution(type)}
                >
                  <Box py={2} pl={3}>
                    <ListItemText key={type} primary={type} />
                    <ListItemSecondaryAction>
                      <ArrowForwardIos fontSize="small" color="primary" />
                    </ListItemSecondaryAction>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

DistributeIdentificationScreen.propTypes = {};
// px={{ xs: 0, sm: "2.75rem" }}
