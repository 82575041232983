import React, { useContext, useState } from 'react';
import {
  Box,
  Typography,
  Container,
  TextField,
  Button
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import {
  getTrips,
  getNetworkDestinations,
  distributionUnitLoad
} from '../../../api-rest';
import { TripProcessContext } from './trip-process-context';
import { useDistributionCenter } from '../../../app/access-control/distribution-center-provider';
import vehicleLicensePlateIsInvalid from '../../../app/utils/br-vehicle-license-plate-validator';
import handleRestAPIErrorWithMessage from '../../../app/utils/handle-rest-api-error-with-message';
import { playErrorBeep } from '../../../sounds';
import HeaderWithCancel from '../../../app/components/header-with-cancel';
import { ActivityTrackingContext } from '../../../app/activity-tracking/activity-tracking-provider';
import { DistributeProcessContext } from '../distribute-process-context';
import { TYPES_TITLE } from '../distribute-identification';

import {
  TRIP_PAGE_STATES,
  ROUTES,
  GET_TRIPS_ERROR_MSGS,
  OPERATIONAL_PROCESS,
  ACTIVITY,
  BASE_TRIP_TRANSFER_ERROR_MSGS,
  SWITCHES,
  DISTRIBUTE_TO
} from '../../../constants';
import { useFeature } from '../../../app/hooks/use-feature';
import Confirmation from '../../../app/components/confirmation';

export default function VehicleLicensePlateInput({ transferType }) {
  // State hooks

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showTransferOption, setShowTransferOption] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  // Context hooks

  const history = useHistory();
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const { setTrip, setSelectedDC, setPageState } = useContext(
    TripProcessContext
  );
  const {
    vehicleLicensePlate,
    setVehicleLicensePlate,
    setDestinationList,
    setDistributionUnitLoadId
  } = useContext(DistributeProcessContext);
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  // Feature flags

  const disableTransportTransferMenu = useFeature(
    SWITCHES.disableTransportTransferMenu
  );
  const disableDistributeTransferBasedOnNetwork = useFeature(
    SWITCHES.disableDistributeTransferBasedOnNetwork
  );

  // Constants

  const distributionCenterRoutingCode = selectedDistributionCenter?.routingCode;
  const tripDate = moment()
    .utc()
    .startOf('day')
    .unix();

  // Handlers

  const handleGoBack = () => {
    const path = distributionCenterRoutingCode ? '/' : '/distribute';
    history.push({ pathname: path });
  };

  const baseErrorHandler = message => {
    playErrorBeep();
    setLoading(false);
    setErrorMessage(message);
  };

  const onlyOneDestination = async destination => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTION_UNIT_LOAD
    );

    const errorMessages = [];

    try {
      const response = await distributionUnitLoad({
        vehicleLicensePlate,
        originRoutingCode: selectedDistributionCenter.routingCode,
        destinations: [destination[0].destinationRoutingCode]
      });

      setDistributionUnitLoadId(response.data.distributionUnitLoadId);
    } catch (error) {
      handleRestAPIErrorWithMessage(
        {
          ...BASE_TRIP_TRANSFER_ERROR_MSGS
        },
        error,
        message => {
          errorMessages.push(message);
        }
      );
    }

    if (errorMessages.length > 0) {
      baseErrorHandler(errorMessages.join('\n'));
    } else {
      history.push({
        pathname: ROUTES.DISTRIBUTE.ADD_CARGO
      });
    }
  };

  const handleSubmitPartnerTransfer = () => {
    history.push({
      pathname: ROUTES.DISTRIBUTE_PARTNER_PROCESS,
      state: { vehicleLicensePlate }
    });
  };

  const handleConfirm = async event => {
    if (loading) return;

    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_GET_TRIP_BEEP
    );

    event.preventDefault();

    setLoading(true);
    setOpenConfirmation(false);

    if (vehicleLicensePlateIsInvalid({ vehicleLicensePlate })) {
      baseErrorHandler(GET_TRIPS_ERROR_MSGS.InvalidLicensePlate);
      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_GET_TRIP_BEEP
      );
      return;
    }

    if (transferType === DISTRIBUTE_TO.PARTNER) {
      setLoading(false);
      handleSubmitPartnerTransfer();

      return;
    }

    if (!disableDistributeTransferBasedOnNetwork) {
      const response = await getNetworkDestinations(
        distributionCenterRoutingCode
      ).catch(error => {
        handleRestAPIErrorWithMessage(
          { ...BASE_TRIP_TRANSFER_ERROR_MSGS },
          error,
          baseErrorHandler
        );
      });

      if (!response) {
        trackEnd(
          OPERATIONAL_PROCESS.BEEP_LATENCY,
          ACTIVITY.DISTRIBUTE_GET_POSSIBLE_DESTINATIONS
        );
        return;
      }

      setSelectedDC(selectedDistributionCenter);

      setLoading(false);

      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_GET_POSSIBLE_DESTINATIONS
      );

      if (response.data.destinations.length === 1) {
        setDestinationList([
          { ...response.data.destinations[0], isSelected: true }
        ]);
        onlyOneDestination(response.data.destinations)
          .then(() => {
            trackEnd(
              OPERATIONAL_PROCESS.BEEP_LATENCY,
              ACTIVITY.DISTRIBUTION_UNIT_LOAD
            );
          })
          .catch(err => err);
      } else {
        setDestinationList(response.data.destinations);
        history.push({
          pathname: ROUTES.DISTRIBUTE.SELECT_POSSIBLE_DESTINATIONS
        });
      }
    } else {
      const response = await getTrips(
        tripDate,
        vehicleLicensePlate,
        distributionCenterRoutingCode
      ).catch(error => {
        handleRestAPIErrorWithMessage(
          { ...BASE_TRIP_TRANSFER_ERROR_MSGS, ...GET_TRIPS_ERROR_MSGS },
          error,
          baseErrorHandler
        );

        setShowTransferOption(true);
      });

      if (!response) {
        trackEnd(
          OPERATIONAL_PROCESS.BEEP_LATENCY,
          ACTIVITY.DISTRIBUTE_GET_TRIP_BEEP
        );
        return;
      }

      const trip = response.data?.trips?.[0];
      if (!trip) {
        baseErrorHandler(GET_TRIPS_ERROR_MSGS.TripNotFound);
        setShowTransferOption(true);
        trackEnd(
          OPERATIONAL_PROCESS.BEEP_LATENCY,
          ACTIVITY.DISTRIBUTE_GET_TRIP_BEEP
        );
        return;
      }

      setTrip(trip);
      setSelectedDC(selectedDistributionCenter);
      setPageState(TRIP_PAGE_STATES.BEEP_CARGO);

      setLoading(false);

      trackEnd(
        OPERATIONAL_PROCESS.BEEP_LATENCY,
        ACTIVITY.DISTRIBUTE_GET_TRIP_BEEP
      );
      history.push({
        pathname: ROUTES.DISTRIBUTE_TRIP_PROCESS
      });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    setOpenConfirmation(true);
  };

  const handleCancel = () => {
    setVehicleLicensePlate('');
    setOpenConfirmation(false);
  };

  return (
    <form onSubmit={handleSubmit} data-testid="vehicle-license-plate-reader">
      <Box bgcolor={colors.root[0]} height="100vh">
        <Box display="flex" flexDirection="column" height={1}>
          <Box flex={1}>
            <Container maxWidth="xs">
              <Box pt={3} overflow="hidden">
                <Box>
                  <HeaderWithCancel
                    testId="receive-cancel-button"
                    handleClose={handleGoBack}
                    isDisabled={loading}
                  />
                  <Box paddingTop={1.5}>
                    <Typography component="div" variant="body1" gutterBottom>
                      <Box fontWeight="fontWeightBold">Distribuir</Box>
                    </Typography>
                  </Box>
                  <Box mt={1.5}>
                    <Typography variant="h5">
                      Digite a placa do veículo e comece a distribuir para
                      {transferType === DISTRIBUTE_TO.PARTNER
                        ? ` ${TYPES_TITLE.PARTNER.toLowerCase()}`
                        : ` ${TYPES_TITLE.TRANSFER.toLowerCase()}`}
                    </Typography>
                  </Box>
                  <Box my={2.5}>
                    <TextField
                      disabled={loading}
                      style={{ width: '100%' }}
                      variant="outlined"
                      value={vehicleLicensePlate}
                      size="small"
                      type="text"
                      InputProps={{
                        inputProps: {
                          'aria-label': 'vehicle-license-plate-reader',
                          maxLength: 7,
                          pattern: '[A-Za-z0-9]{1,7}',
                          title: 'Somente letras e números são permitidos'
                        }
                      }}
                      required
                      placeholder="Placa do veículo"
                      data-testid="vehicle-license-plate-input-field"
                      onChange={event => {
                        setVehicleLicensePlate(
                          event.target.value.toUpperCase()
                        );
                      }}
                    />
                    {errorMessage && (
                      <Box mt={1.5}>
                        <Alert severity="error">{errorMessage}</Alert>
                      </Box>
                    )}
                  </Box>
                  {disableTransportTransferMenu && showTransferOption && (
                    <Box mt={1.5}>
                      <Link
                        label="Ir para menu transportes"
                        data-testid="go-to-transport-menu"
                        to={{
                          pathname: '/add-bag-to-transfer',
                          state: {
                            showButtonStartTransfer: true
                          }
                        }}
                      >
                        <Typography gutterBottom>
                          Ir para menu transportes
                        </Typography>
                      </Link>
                    </Box>
                  )}
                </Box>
              </Box>
            </Container>
          </Box>

          <Container maxWidth="xs">
            <Box flex="initial" mb={3}>
              <Box display="flex">
                <Box flex={1}>
                  <Button
                    disabled={loading}
                    type="submit"
                    data-testid="comecar-button"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Começar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
          {openConfirmation && (
            <Confirmation
              open={openConfirmation}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              subtitleText="Confirma a placa do veículo?"
              confirmText="Sim"
              cancelText="Não"
              zIndex={2}
            />
          )}
        </Box>
      </Box>
    </form>
  );
}

VehicleLicensePlateInput.propTypes = {
  transferType: PropTypes.oneOf([DISTRIBUTE_TO.PARTNER, DISTRIBUTE_TO.TRANSFER])
};

VehicleLicensePlateInput.defaultProps = {
  transferType: DISTRIBUTE_TO.TRANSFER
};
