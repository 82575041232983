import {
  ForgotPassword,
  Signin,
  ValidateRecoveryPassword,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  VALIDATE_PASSWORD_RECOVERY
} from '@loggi/authentication';

import React, { useEffect, useState } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import 'moment/locale/pt-br';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SvgIcon from '@material-ui/core/SvgIcon';

import { smoke } from '@loggi/mar/src/principles/colors';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import Dispatch from '../views/dispatch';
import Identify from '../views/identify/identify';
import DistributePackageReaderPage from '../views/distribute/distribute-package-reader-page';
import DistributeIdentification from '../views/distribute/distribute-identification';
import RedispatchPackageReaderPage from '../views/distribute/redispatch-package-reader-page';
import BagReaderPage from '../views/distribute/bag-reader-page';
import ContextReader from '../views/distribute/context-reader';
import ReceiveContextReader from '../views/receive/context-reader';
import ReceivePackage from '../views/receive/receive';
import ReceiveTransfers from '../views/receive/receive-transfer';
import ReceiveRedispatch from '../views/receive/redispatch/receive-redispatch';
import ReceiveCounter from '../views/receive/counter/receive-counter';
import ChangeLabel from '../views/change-label';
import EditPackageStatus from '../views/edit-package-status';
import PrinterIpp from '../views/printer/printer';
import ChangeDirection from '../views/change-direction';
import { PhotoUpload } from '../views/distribute/photo-upload';
import { MeliChecking } from '../views/meli-checking';

import { AddBagToTransfer } from '../views/add-bag-to-transfer';
import { UnitLoadManagement, ConfirmPackageRemoval } from '../views/unit-load';
import { Sorting } from '../views/sort';
import { Packing } from '../views/packing';
import { StartTransfer } from '../views/start-transfer';
import { Transport } from '../views/transport';
import { withNavigationMenu, Home } from './navigation';
import { ACTIONS } from './access-control/access-control-rules';
import CustomRoute from './access-control/custom-route';

import { ReactComponent as LogoLoggi } from '../assets/images/logo-loggi.svg';
import { ChooseDistributionCenter } from '../views/choose-distribution-center';
import { ReactComponent as PackagesImage } from '../assets/images/empty-packages.svg';
import PageNotFound from '../views/page-not-found';
import UpdateAvailableDialog from './components/update-available-dialog';
import { ReceivingProcessProvider } from '../views/receive/receiving-process-context';
import { DistributeProcessProvider } from '../views/distribute/distribute-process-context';
import InitialIdentification from '../views/receive/initial-identification';
import Prepare from '../views/prepare/prepare';
import VehicleLicensePlateInput from '../views/distribute/trip/vehicle-license-plate-input';
import NewDistributionScreen from '../views/distribute/new-distribution-screen';
import TripProcess from '../views/distribute/trip/trip-process';
import { TripProcessProvider } from '../views/distribute/trip/trip-process-context';
import { ROUTES, ROUTING_CODE_SENTRY_TAG } from '../constants';
import ChooseLabelType from '../views/prepare/choose-label-type';
import MovePackagesBulk from '../views/receive/move-packages-bulk';
import ReceivedPackages from '../views/receive/received-packages';
import OrganizeInitialIdentification from '../views/organize/initial-identification';
import { OrganizeProcessProvider } from '../views/organize/organize-process-context';
import OrganizePackage from '../views/organize/organize-package';
import OrganizeUnitLoad from '../views/organize/organize-unit-load';
import OrganizeSummary from '../views/organize/summary';
import DeclarePackageDamage from '../views/declare-package-damage/declare-package-damage';
import { useDistributionCenter } from './access-control/distribution-center-provider';
import IdentifyPackageReader from '../views/identify/identify-package-reader';
import MatchImageDamagedPackage from '../views/declare-package-damage/v2/match-image-damaged-package';
import UploadImageDamagePackage from '../views/declare-package-damage/v2/upload-image-damage-package';
import { DamagedPackageProvider } from '../views/declare-package-damage/v2/damaged-package-context';
import ConfirmReceivement from '../views/receive/confirm-receivement';
import FormDamagedPackage from '../views/declare-package-damage/v2/form-damaged-package';
import IdentifyDestinationScreen from '../views/distribute/identify-destination-screen';
import AddCargoScreen from '../views/distribute/add-cargo-screen';
import DistributionUnitLoadCargoReview from '../views/distribute/trip/distribution-unit-load-cargo-review';
import DistributionUnitLoadEditCargoList from '../views/distribute/distribution-unit-load-edit-cargo-list';
import CancelDistributionScreen from '../views/distribute/cancel-distribution-screen';
import BagPackageReaderPage from '../views/distribute/bag-package-reader-page';
import LicensePlateInputPage from '../views/distribute/license-plate-input-page';

moment.updateLocale('pt-BR');

const useHeaderStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    height: spacing(5),
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    [breakpoints.up('md')]: {
      right: 'auto',
      width: '100vw'
    }
  },
  logoLink: {
    alignItems: 'center',
    color: palette.primary.contrastText,
    display: 'flex'
  },
  container: {
    display: 'flex'
  }
}));

const logoutStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
    justifyContent: 'center'
  },
  centered: {
    alignSelf: 'center'
  }
});

export function Logout() {
  const { signOut } = useAmplifyAuth();
  const [loading, setLoading] = useState(true);
  const [renderError, shouldRenderError] = useState(false);

  const classes = logoutStyles();

  const logout = async () => {
    setLoading(true);
    shouldRenderError(false);
    await signOut().catch(err => {
      shouldRenderError(true);
      Sentry.captureException(err);
    });
    setLoading(false);
  };

  useEffect(() => {
    logout()
      .then(() => {})
      .catch(err => err);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Box mb={2} className={classes.centered}>
        {loading && <CircularProgress />}
      </Box>
      {renderError && (
        <Box data-testid="logout-error-content">
          <Box mb={2} className={classes.centered}>
            <PackagesImage />
          </Box>
          <Box>
            <Typography
              align="center"
              component="div"
              variant="body1"
              gutterBottom
            >
              <Box fontWeight="fontWeightBold">Falha ao tentar deslogar</Box>
            </Typography>
            <Typography
              align="center"
              component="div"
              variant="body1"
              gutterBottom
            >
              Ocorreu um erro não esperado.
            </Typography>
          </Box>
          <Box mt={2}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={logout}
            >
              Tentar novamente
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export function ReceiveRoutes() {
  return (
    <ReceivingProcessProvider>
      <Switch>
        <Route exact path="/receive" component={ReceivePackage} />
        <Route exact path="/receive/transfers" component={ReceiveTransfers} />
        <Route exact path="/receive/redispatch" component={ReceiveRedispatch} />
        <Route exact path="/receive/counter" component={ReceiveCounter} />
        <Route
          exact
          path="/receive/confirmation"
          component={ConfirmReceivement}
        />
        <Route
          exact
          path={ROUTES.RECEIVE.MOVE_PACKAGES_BULK}
          component={MovePackagesBulk}
        />
        <Route
          exact
          path={ROUTES.RECEIVE.RECEIVED_PACKAGES}
          component={ReceivedPackages}
        />
        <Route
          exact
          path={ROUTES.RECEIVE_INITIAL_IDENTIFICATION}
          component={InitialIdentification}
        />
        <Route
          path={ROUTES.RECEIVE.CONTEXT_READER}
          component={withNavigationMenu(ReceiveContextReader)}
        />
        <Route component={PageNotFound} />
      </Switch>
    </ReceivingProcessProvider>
  );
}

export function DistributeRoutes() {
  return (
    <DistributeProcessProvider>
      <TripProcessProvider>
        <Switch>
          <Route
            exact
            path={ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION}
            component={VehicleLicensePlateInput}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE_PARTNER}
            component={LicensePlateInputPage}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE_PARTNER_PROCESS}
            component={BagPackageReaderPage}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE_TRIP_PROCESS}
            component={TripProcess}
          />
          <Route
            path={ROUTES.DISTRIBUTE.IDENTIFICATION}
            component={DistributeIdentification}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE.SELECT_POSSIBLE_DESTINATIONS}
            component={IdentifyDestinationScreen}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE.ADD_CARGO}
            component={AddCargoScreen}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE.REVIEW_CARGO}
            component={DistributionUnitLoadCargoReview}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE.NEW_DISTRIBUTION}
            component={NewDistributionScreen}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE.EDIT_CARGO}
            component={DistributionUnitLoadEditCargoList}
          />
          <Route
            exact
            path={ROUTES.DISTRIBUTE.CANCEL_DISTRIBUTION}
            component={CancelDistributionScreen}
          />
          <Route component={PageNotFound} />
        </Switch>
      </TripProcessProvider>
    </DistributeProcessProvider>
  );
}

export function OrganizeRoutes() {
  return (
    <OrganizeProcessProvider>
      <Switch>
        <Route
          exact
          path={ROUTES.ORGANIZE.ORGANIZE_PACKAGE}
          component={OrganizePackage}
        />
        <Route
          exact
          path={ROUTES.ORGANIZE.INITIAL_IDENTIFICATION}
          component={OrganizeInitialIdentification}
        />
        <Route
          exact
          path={ROUTES.ORGANIZE.ORGANIZE_UNIT_LOAD}
          component={OrganizeUnitLoad}
        />
        <Route
          exact
          path={ROUTES.ORGANIZE.SUMMARY}
          component={OrganizeSummary}
        />
        <Route component={PageNotFound} />
      </Switch>
    </OrganizeProcessProvider>
  );
}

export function DamagedPackageRoutes() {
  return (
    <DamagedPackageProvider>
      <Switch>
        <Route
          exact
          path={ROUTES.DAMAGED_PACKAGE.MATCH_IMAGE}
          component={MatchImageDamagedPackage}
        />
        <Route
          exact
          path={ROUTES.DAMAGED_PACKAGE.PREVISUALIZE_IMAGE}
          component={UploadImageDamagePackage}
        />
        <Route
          exact
          path={ROUTES.DAMAGED_PACKAGE.FORM}
          component={FormDamagedPackage}
        />
        <Route component={PageNotFound} />
      </Switch>
    </DamagedPackageProvider>
  );
}

export function AllowedRoutes() {
  const {
    state: { authenticatedUser }
  } = useAmplifyAuth();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();

  useEffect(() => {
    Sentry.setUser({ email: authenticatedUser?.email });
    Sentry.setTag(
      ROUTING_CODE_SENTRY_TAG,
      selectedDistributionCenter?.routingCode
    );
  }, [authenticatedUser, selectedDistributionCenter]);

  return (
    <Switch>
      <CustomRoute
        actions={[]}
        exact
        path="/"
        component={withNavigationMenu(Home)}
      />
      <CustomRoute
        actions={[ACTIONS.SORT]}
        path="/sorting"
        component={withNavigationMenu(Sorting)}
        name="Separação"
      />
      <CustomRoute
        actions={[ACTIONS.PACKING]}
        path="/packing"
        component={withNavigationMenu(Packing)}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        exact
        path="/identify"
        component={withNavigationMenu(Identify)}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        path="/identify/identify-package-reader"
        component={IdentifyPackageReader}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        exact
        path="/identify/edit-package-status"
        component={withNavigationMenu(EditPackageStatus)}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        exact
        path="/identify/change-direction"
        component={withNavigationMenu(ChangeDirection)}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        exact
        path="/identify/declare-package-damage"
        component={withNavigationMenu(DeclarePackageDamage)}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        path={ROUTES.DAMAGED_PACKAGE.V2}
        component={DamagedPackageRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        exact
        path="/distribute/photo-upload"
        component={PhotoUpload}
      />
      <CustomRoute
        actions={[
          ACTIONS.DISTRIBUTE,
          ACTIONS.DISTRIBUTE_TRANSFERS,
          ACTIONS.DISTRIBUTE_PARTNERS
        ]}
        exact
        path="/distribute"
        component={withNavigationMenu(ContextReader)}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE_TRANSFERS]}
        path={ROUTES.DISTRIBUTE_TRANSFERS_INITIAL_IDENTIFICATION}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE_PARTNERS]}
        path={ROUTES.DISTRIBUTE_PARTNER}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE_PARTNERS]}
        path={ROUTES.DISTRIBUTE_PARTNER_PROCESS}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE]}
        path="/distribute/bag-reader"
        component={BagReaderPage}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE]}
        path="/distribute/distribute-package-reader"
        component={DistributePackageReaderPage}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE_PARTNERS]}
        path={ROUTES.DISTRIBUTE_PARTNER_PROCESS}
        component={BagPackageReaderPage}
      />
      <CustomRoute
        actions={[
          ACTIONS.DISTRIBUTE,
          ACTIONS.DISTRIBUTE_TRANSFERS,
          ACTIONS.DISTRIBUTE_REDISPATCH,
          ACTIONS.DISTRIBUTE_PARTNERS
        ]}
        path={ROUTES.DISTRIBUTE.IDENTIFICATION}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE, ACTIONS.DISTRIBUTE_TRANSFERS]}
        path={ROUTES.DISTRIBUTE.SELECT_POSSIBLE_DESTINATIONS}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE, ACTIONS.DISTRIBUTE_TRANSFERS]}
        path={ROUTES.DISTRIBUTE.ADD_CARGO}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[
          ACTIONS.DISTRIBUTE,
          ACTIONS.DISTRIBUTE_TRANSFERS,
          ACTIONS.DISTRIBUTE_PARTNERS
        ]}
        path={ROUTES.DISTRIBUTE.NEW_DISTRIBUTION}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE, ACTIONS.DISTRIBUTE_TRANSFERS]}
        path={ROUTES.DISTRIBUTE.REVIEW_CARGO}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE, ACTIONS.DISTRIBUTE_TRANSFERS]}
        path={ROUTES.DISTRIBUTE.EDIT_CARGO}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE_TRANSFERS, ACTIONS.DISTRIBUTE_PARTNERS]}
        path={ROUTES.DISTRIBUTE.CANCEL_DISTRIBUTION}
        component={DistributeRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE_PARTNERS]}
        path={ROUTES.DISTRIBUTE_PARTNER}
        component={LicensePlateInputPage}
      />

      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE_REDISPATCH]}
        path="/distribute/redispatch-package-reader"
        component={RedispatchPackageReaderPage}
      />
      <CustomRoute
        actions={[ACTIONS.DISPATCH]}
        path="/dispatch"
        component={Dispatch}
        exact
      />
      <CustomRoute
        actions={[ACTIONS.RECEIVE]}
        path="/receive"
        component={ReceiveRoutes}
      />
      <CustomRoute
        actions={[ACTIONS.CHANGE_LABEL]}
        path="/change-label"
        component={withNavigationMenu(ChangeLabel)}
      />
      <CustomRoute
        actions={[ACTIONS.PRINTER]}
        path="/printer"
        component={withNavigationMenu(PrinterIpp)}
      />
      <CustomRoute
        actions={[ACTIONS.TRANSPORT]}
        path="/meli-checking"
        component={withNavigationMenu(MeliChecking)}
      />
      <CustomRoute
        actions={[ACTIONS.TRANSPORT]}
        path="/add-bag-to-transfer"
        component={withNavigationMenu(AddBagToTransfer)}
      />
      <CustomRoute
        actions={[ACTIONS.TRANSPORT]}
        path="/start-transfer"
        component={withNavigationMenu(StartTransfer)}
        name="Transferências"
      />
      <CustomRoute
        actions={[ACTIONS.TRANSPORT]}
        path="/transport"
        component={withNavigationMenu(Transport)}
        name="Transportes"
      />
      <CustomRoute
        actions={[ACTIONS.UNIT_LOAD]}
        exact
        path="/unit-load"
        component={UnitLoadManagement}
      />
      <CustomRoute
        actions={[ACTIONS.UNIT_LOAD]}
        exact
        path="/unit-load/confirm-package-removal"
        component={withNavigationMenu(ConfirmPackageRemoval)}
      />
      <CustomRoute
        actions={[]}
        exact
        path="/choose-distribution-center"
        component={ChooseDistributionCenter}
      />
      <CustomRoute
        actions={[ACTIONS.IDENTIFY]}
        exact
        path="/identify/edit-package-status"
        component={withNavigationMenu(EditPackageStatus)}
      />
      <CustomRoute
        actions={[ACTIONS.DISTRIBUTE]}
        exact
        path="/distribute/photo-upload"
        component={PhotoUpload}
      />
      <CustomRoute
        actions={[ACTIONS.PREPARE]}
        exact
        path={ROUTES.PREPARE.CHOOSE_LABEL_TYPE}
        component={ChooseLabelType}
      />
      <CustomRoute
        actions={[ACTIONS.PREPARE]}
        exact
        path="/prepare"
        component={Prepare}
      />
      <CustomRoute
        actions={[ACTIONS.ORGANIZE]}
        path={ROUTES.ORGANIZE.INITIAL_IDENTIFICATION}
        component={OrganizeRoutes}
      />
      <Route path="/logout" component={Logout} />
      <Redirect exact from={LOGIN_ROUTE} to="/" />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export function AppVersion() {
  return (
    <Box color={smoke[700]} marginTop={2} data-testid="app-version">
      {process.env.REACT_APP_VERSION}
    </Box>
  );
}

export function AuthenticationRoutes() {
  return (
    <Switch>
      <Route
        exact
        path={LOGIN_ROUTE}
        render={() => (
          <>
            <UpdateAvailableDialog />
            <Signin
              showAppleSignIn={false}
              showCreateAccount={false}
              appVersionComponent={<AppVersion />}
            />
          </>
        )}
      />
      <Route
        path={VALIDATE_PASSWORD_RECOVERY}
        component={ValidateRecoveryPassword}
      />
      <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
      <Redirect to={LOGIN_ROUTE} />
    </Switch>
  );
}

export function Header() {
  const styles = useHeaderStyles();

  return (
    <Box
      alignItems="center"
      className={styles.root}
      color="primary.contrastText"
      display="flex"
      mt={{ xs: 3, sm: 5 }}
    >
      <Container component={Box} height="inherit" className={styles.container}>
        <Box display="flex" mr={3}>
          <a className={styles.logoLink} data-testid="logo-loggi-id" href="/">
            <SvgIcon color="inherit" component={LogoLoggi} />
          </a>
        </Box>
      </Container>
    </Box>
  );
}
